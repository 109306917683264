<!--Hero home -->
<app-hero-home [hero__title]="hero__title" [hero__baseline]="hero__baseline"></app-hero-home>
<!-- Content body -->
<div class="aui ltr yui3-js-enabled webkit ltr js chrome chrome109 chrome109-0 win secure">
    <div class="blocks-container">
        <div class="wp-block-columns alignfull is-layout-flex wp-container-core-columns-is-layout-1 wp-block-columns-is-layout-flex">
            <div class="wp-block-column is-vertically-aligned-top is-layout-flow wp-block-column-is-layout-flow" style="flex-basis:100%">
                <div class="col-12" id="main-content" role="main">
                    <div class="portlet-layout row-fluid">
                        <div class="portlet-column portlet-column-only span12" id="column-1">
                            <div class="portlet-dropzone portlet-column-content portlet-column-content-only"
                                id="layout-column_column-1">


                                                <div class="col-10 offset-1 mb-5">
                                                    <div class="editor-content">
                                                        <p>In questa area trovi i set informativi dei nostri prodotti assicurativi. 
                                                            Inserisci il nome del tuo prodotto o un’altra parola chiave nel campo di ricerca per trovare 
                                                            l’edizione del set informativo di tuo riferimento o utilizza la ricerca avanzata. 
                                                            Ti ricordiamo che se non trovi il documento che stai cercando puoi consultare il nostro 
                                                            <a href="#">archivio</a> delle edizioni che non sono più commercializzate.
                                                        </p>
                                                    </div>
                                                </div>


                                <div class="portlet-boundary portlet-boundary_73_ portlet-static portlet-static-end portlet-borderless portlet-breadcrumb "
                                    id="p_p_id_73_INSTANCE_Tk7dOW2GWle8_"> <span id="p_73_INSTANCE_Tk7dOW2GWle8"></span>
                                    <div class="portlet-borderless-container">
                                        <div class="portlet-body col-12 d-flex justify-content-between">
                                            <ul aria-label="Barra di Navigazione" class=" breadcrumb breadcrumb-horizontal" style="margin: 0 auto 15px 0;">
                                            </ul>
                                            <div class="advanced-search-button">
                                                <span class="txt-remove" (click)="toggleVisibleFilterColumns()">
                                                    Ricerca avanzata
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="portlet-boundary portlet-boundary_ddlsearch_WAR_bpccsfctddlexportportlet_ portlet-static portlet-static-end portlet-borderless "
                                    id="p_p_id_ddlsearch_WAR_bpccsfctddlexportportlet_"> <span
                                        id="p_ddlsearch_WAR_bpccsfctddlexportportlet"></span>
                                    <div class="portlet-borderless-container">
                                        <div class="portlet-body">
                                                            <div class="col-12">


                                                <!--    Tabella -->
                                                <app-tablesetinformativi
                                                    [visibleFilterColumns]="visibleFilterColumns">
                                                </app-tablesetinformativi>


                                            </div>
                                            <div id="aui_popup_content"> </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>