<div class="header__pre">
    <div class="container">
        <div class="header__pre-item">
            <div class="nav">
                <app-menu-social></app-menu-social>
            </div>
        </div>
        <div class="header__pre-item">
            <div class="nav">
                <ul id="group__menu" class="menu group__menu">
                    <li id="menu-item-55" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-55">
                        <a href="https://bnpparibascardif-preprod.go-vip.net/italy/chi-siamo/">Chi Siamo</a></li>
                    <li id="menu-item-56" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-56">
                        <a href="https://bnpparibascardif-preprod.go-vip.net/italy/contattaci/">Contattaci</a></li>
                    <li id="menu-item-216"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-216"><a
                            href="https://area-personale.bnpparibascardif.it/content/mycardif/it/on-boarding.html">MyCardif</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>