import { Component } from '@angular/core';

@Component({
  selector: 'app-setinformativi',
  templateUrl: './setinformativi.component.html'
})
export class SetinformativiComponent {
  visibleFilterColumns:boolean = false;
  hero__title:string = "Set Informativi";
  hero__baseline:string="I set informativi delle tue polizze assicurative";
  toggleVisibleFilterColumns(){
    this.visibleFilterColumns = !this.visibleFilterColumns;
  }
}
